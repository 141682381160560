// eslint-disable-next-line no-restricted-imports
import { createRoot as createRootOriginal } from "react-dom/client";
const untrailingslashit = (str)=>str.endsWith("/") || str.endsWith("\\") ? untrailingslashit(str.slice(0, -1)) : str;
const trailingslashit = (str)=>`${untrailingslashit(str)}/`;
/**
 * Renders a React component using either createRoot from react-dom/client (React 18+) or render from ReactDOM (React 17 and below).
 *
 * This is currently only needed for Divi compatibility as Divi is still using React v16 and WordPress < 6.2 as it uses React v17.
 *
 * We are using a custom webpack configuration to replace the react-dom/client dependency with our own package.
 *
 * @see https://app.clickup.com/t/86959qqq1
 * @deprecated When we drop support for WordPress < 6.2 we can safely migrate to `createRoot()`
 */ export function createRoot(container) {
    try {
        return createRootOriginal(container);
    } catch (error) {
        return {
            render: (element)=>{
                var _window_ReactDOM_render, _window_ReactDOM;
                return (_window_ReactDOM = window.ReactDOM) == null ? void 0 : (_window_ReactDOM_render = _window_ReactDOM.render) == null ? void 0 : _window_ReactDOM_render.call(_window_ReactDOM, element, container);
            },
            unmount: ()=>{
                var _window_ReactDOM_unmountComponentAtNode, _window_ReactDOM;
                return (_window_ReactDOM = window.ReactDOM) == null ? void 0 : (_window_ReactDOM_unmountComponentAtNode = _window_ReactDOM.unmountComponentAtNode) == null ? void 0 : _window_ReactDOM_unmountComponentAtNode.call(_window_ReactDOM, container);
            }
        };
    }
}
export { untrailingslashit, trailingslashit };
